<template>
<div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2 mb-base">
            <h2>{{$t('Payments')}}</h2>
          </div>
          <div class="flex vx-col w-full lg:w-1/2 items-end">
            <vs-button class="ml-auto mr-5 mb-4 self-center" color="success" @click="showQuickbooks">Sync Quickbooks</vs-button> 
            <div class="flex justify-between mr-4 mb-4">
              <span class="font-semibold"></span>
              <span class="font-medium text-primary cursor-pointer">
                <vs-button
                  @click="newPayment('Add')"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-plus"
                  size="large"
                >
                </vs-button>
              </span>
            </div>
          </div>
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          orders
          pagination
          :max-items="maximumItems"
          :data="payments"
          @search="handleSearch"
          @keyup.enter.native="handleSearch"
          search
          :tableLoader="tableLoader"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
          </div>
          <template slot="thead">
            <shipblu-th>{{$t('Date')}}</shipblu-th>
            <shipblu-th>{{$t('Amount')}}</shipblu-th>
            <shipblu-th>{{$t('Description')}}</shipblu-th>
            <shipblu-th>{{$t('Reference Number')}}</shipblu-th>
            <shipblu-th>{{$t('Deposit Slip')}}</shipblu-th>
            <shipblu-th>{{$t('Statement')}}</shipblu-th>
            <shipblu-th>{{$t('Account')}}</shipblu-th>
            <shipblu-th>{{$t('Paid To')}}</shipblu-th>
            <shipblu-th>{{$t('Status')}}</shipblu-th>
            <shipblu-th>{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
              <shipblu-td :data="data[indextr].date">
                {{ data[indextr].date }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].amount">
                {{ data[indextr].amount ? data[indextr].amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].description">
                {{ data[indextr].description }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].reference_number">
                {{ data[indextr].reference_number }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].link_to_deposit_slip">
                <a @click="downloadPayment(data[indextr])" target="_blank">{{$t('Download')}}</a>
              </shipblu-td>

              <shipblu-td :data="data[indextr].statement">
                {{ data[indextr].statement ? data[indextr].statement.number : ''}}
              </shipblu-td>

              <shipblu-td :data="data[indextr].account">
                {{ data[indextr].account ? data[indextr].account.name : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].paid_to">
                {{ data[indextr].paid_to ? data[indextr].paid_to.name : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
                <div class="m-auto flex items-center justify-center font-semibold">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                </div>
              </shipblu-td>

              <shipblu-td>
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-more-horizontal"
                    class="mr-2"
                  >
                  </vs-button>
                  <vs-dropdown-menu style="width: 190px">
                    <vs-dropdown-item @click="newPayment('Edit', data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="EditIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Edit')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
      </form>
    </div>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-payment :type="type" :accounts="accounts" :paymentModal="paymentModal" :status="status" :statements="statements"
    @paymentModal="paymentModal = $event" :payment="payment" @loadPayments="loadPayments"></add-payment>
  </div>
</template>
<script>
import AddPayment from './AddPayment.vue'
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  components: { 
    AddPayment,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      paymentModal: false,
      type: '',
      selected: [],
      payments: [
        {
          account: {
            id: ''
          },
          statement: {
            id: ''
          }
        }
      ],
      statements: [],
      status: [
        'requested',
        'paid',
        'cancelled'
      ],
      payment: {},
      searchInProgress: false,
      searchedValue: ' ',
      searchVal: '',
      tableLoader: false,
      accounts: []
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPayments()
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadPaymentsSearch)
    },
    loadPaymentsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadPayments()
    },
    loadPayments () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/accounting/payments/${query}`, 'get', null, true, 
        (response) => {
          this.payments = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadPaymentAccounts () {
      sendRequest(false, false, this, 'api/v1/accounting/payment-accounts/', 'get', null, true, 
        (response) => {
          this.accounts = response.data.results
        }
      )
      this.searchInProgress = false
    },
    loadStatements () {
      sendRequest(false, false, this, 'api/v1/billing/statements/?limit=100&net_cod_due=0', 'get', null, true, 
        (response) => {
          this.statements = response.data.results
        }
      )
    },
    newPayment (type, data) {
      this.type = type
      if (type === 'Add') {
        this.payment = {}
      } else {
        this.payment = data
      }
      this.paymentModal = true
    },
    downloadPayment (data) {
      sendRequest(false, false, this, `api/v1/accounting/payments/${data.id}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    showQuickbooks () {
      sendRequest(false, false, this, 'api/v1/accounting/quickbooks-sync/payments/', 'post', {}, true, 
        (respone) => {
          this.$vs.notify({
            color:'warning',
            title: 'Warning',
            text: respone.data,
            position: 'top-center'
          })
        }
      )
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPayments()
    this.loadStatements()
    this.loadPaymentAccounts()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
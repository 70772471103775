<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="paymentModal"
    :title="$t(type) + ' ' + $t('Payment')"
    :buttons-hidden="true" autocomplete="nofill">
      <div>
        <input name="file" type="file" @change="changeImage" class="hidden" ref="fileInput">
        <div
          @click="$refs.fileInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="w-4/5 m-auto mt-10 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
          <div>
            <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
            <span>{{$t('Drop File or')}} </span>
            <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
          </div>
        </div>
      </div>
      <div class="text-left">
          <ul class="flex">
            <li>
              <vs-radio :disabled="!editable" name="fleet manager" v-validate="'required'" v-model="paymentType" vs-value="cod">{{$t('COD')}}</vs-radio>
            </li>
            <li class="ml-3">
              <vs-radio :disabled="!editable" name="fleet manager" v-validate="'required'" v-model="paymentType" vs-value="cash">{{$t('Cash')}}</vs-radio>
            </li>
          </ul>
        <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first('reason') }}</span>
      </div>
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="status" v-model="payment.status" 
          v-validate="'required'" :placeholder="$t('Status') + '*'" label="status" :options="status"/>
          <span class="text-danger text-sm" v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <datepicker v-validate="'required'" name="date" :placeholder="$t('Date') + '*'" v-model="payment.date"  class="mt-8 w-full"></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('date')">{{ errors.first('date') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|decimal'" name="amount" :label-placeholder="$t('Amount') + '*'" v-model="payment.amount" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="reference number" :label-placeholder="$t('Reference Number') + '*'" v-model="payment.reference_number" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('reference number')">{{ errors.first('reference number') }}</span>
        </div>
        <div v-if="paymentType === 'cod'" class="vx-col w-1/2">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="statement" v-model="payment.statement" 
          v-validate="'required'" :placeholder="$t('Statement') + '*'" label="number" :options="statements"/>
          <span class="text-danger text-sm" v-show="errors.has('statement')">{{ errors.first('statement') }}</span>
        </div>
        <div v-else class="vx-col w-1/2">
          <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="account" v-model="payment.account" 
          v-validate="'required'" :placeholder="$t('Account') + '*'" label="name" :options="accounts"/>
          <span class="text-danger text-sm" v-show="errors.has('account')">{{ errors.first('account') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <v-select @search="handleSearch" @keyup.enter.native="handleSearch" search autocomplete="nofill" class="select-large mt-8 w-full" name="paid to"
          v-model="payment.paid_to"  v-validate="'required'" :placeholder="$t('Paid To') + '*'" label="name" :options="merchants"/>
          <span class="text-danger text-sm" v-show="errors.has('paid to')">{{ errors.first('paid to') }}</span>
        </div>
        <div class="vx-col w-full">
          <vs-textarea no-resize v-validate="'required'" name="description" :placeholder="$t('Description')" rows="3" v-model="payment.description" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="type === 'Add'" @click="addPayment" class="active-btn btn">{{ $t('Add') }}</button>
        <button v-else @click="updatePayment" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
  </shipblu-prompt>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import i18nData from '../../i18n/i18nData.js'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['paymentModal', 'type', 'payment', 'statements', 'accounts', 'status'],
  data () {
    return {
      file: '',
      file_key: '',
      paymentType: 'cod',
      editable: false,
      merchants: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' '
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    Datepicker
  },
  watch: {
    'payment.date' (val) {
      this.payment.date = common.formatDate(val)
    },
    'payment.statement' (val) {
      if (val && val.number) {
        this.paymentType = 'cod'
      } else {
        this.paymentType = 'cash'
      }
    },
    'searchVal' (val) {
      if (val === '') {
        this.merchants = []
      }
    },
    type () {
      if (this.type === 'Edit') {
        this.editable = false
        this.file = this.payment.deposit_slip_key
      } else {
        this.editable = true
        this.payment.date = ''
        this.file = ''
      }
    }
  },
  methods: {
    changeImage () {
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.file = URL.createObjectURL(file)
      }
      const formData = new FormData()
      formData.append('file', this.$refs.fileInput.files[0])
      sendRequest(false, false, this, 'api/v1/upload-payment-file/', 'post', formData, true,
        (response) => {
          this.file_key = response.data.file_key
        }
      )
    },
    closeModal () {
      this.$emit('paymentModal', false)
      this.$emit('loadPayments')
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMerchantsSearch)
    },
    loadMerchantsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadMerchants()
      }
    },
    loadMerchants () {
      this.searchInProgress = true
      const query = `?name=${this.searchVal}`
      sendRequest(false, false, this, `api/v1/merchants/${query}`, 'get', null, true,
        (response) => {
          this.merchants = response.data.results
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    addPayment () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.payment.paid_to = this.payment.paid_to ? this.payment.paid_to.id : ''
          this.payment.statement = this.payment.statement ? this.payment.statement.id : ''
          this.payment.account = this.payment.account ? this.payment.account.id : ''
          this.payment.deposit_slip_key = this.file_key
          sendRequest(false, false, this, `api/v1/accounting/payments/${this.paymentType}/`, 'post', this.payment, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Payment'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.$emit('paymentModal', false)
              this.$emit('loadPayments')
            }
          )
          
        }
      })
    },
    updatePayment () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.payment.account = this.payment.account ? this.payment.account.id : ''
          this.payment.paid_to = this.payment.paid_to ? this.payment.paid_to.id : ''
          this.payment.statement = this.payment.statement.id
          this.payment.deposit_slip_key = this.file_key
          sendRequest(false, false, this, `api/v1/accounting/payments/${this.paymentType}/${this.payment.id}/`, 'patch', this.payment, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Payment'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.$emit('paymentModal', false)
              this.$emit('loadPayments')
            }
          )
        }
      })
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    }
  }
}
</script>